import React, { useState } from 'react';
import { Switch } from 'antd';
import { EditOutlined, CheckOutlined, CloseOutlined } from '@ant-design/icons';
import './splits.scss';

const Splits = ({ initialData, setSplitsData, onSubmit }) => {
  const [editMode, setEditMode] = useState(null); // Track the party being edited
  // Mapping for the party names
  const partyNameMap = {
    taggr: 'Taggr',
    operator: 'Operator',
    tiretag: 'TireTag',
    taggrsolutions: 'Taggr Solutions'
  };

  const handleSplitChange = (e, party, type) => {
    if (type === 'value') {
      let inputValue = e.target.value;
      inputValue = inputValue.replace(/^0+/, '') || '0';
      let newValue = Number(inputValue);
      if (newValue > 100) newValue = 100;
      setSplitsData((prevData) => {
        const currentSplits = { ...prevData.splits };
        if (party === 'operator' || party === 'taggrsolutions') {
          const otherParty = party === 'operator' ? 'taggrsolutions' : 'operator';
          const remainingValue = 100 - newValue;
          if (remainingValue >= 0) {
            currentSplits[party].value = newValue;
            currentSplits[otherParty].value = remainingValue;
          }
        }
        else {
          currentSplits[party].value = newValue;
        }
        return { ...prevData, splits: currentSplits };
      })
    } else if (type === 'mode') {
      setSplitsData((prevData) => ({
        ...prevData,
        splits: {
          ...prevData.splits,
          [party]: { ...prevData.splits[party], mode: e ? 'fixed' : 'percentage' },
        },
      }));
    }
  };

  const handleSubmit = () => {
    console.log(initialData, "<==initial data")
    if (initialData?.splits) {
      onSubmit("submit");
    }
    setEditMode(null); // Reset edit mode after submission
  };

  return (
    <div className="splits-container">
      <div className="splits-section-title">Taggr Notice Splits</div>
      <div>
        {["taggr", "operator", "taggrsolutions"].map((party, index) => (
          <div key={index} className="splits-item">
            <div className="split-party-title">
              {partyNameMap[party]} {/* Display the mapped party name */}
            </div>
            <div className='split-second-container'>
              <input
                type="text"
                inputMode="numeric" 
                pattern="[0-9]*"
                value={initialData?.splits?.[party]?.value ?? ""}
                placeholder={`Enter ${initialData?.splits[party]?.mode === 'fixed' ? 'amount' : 'percentage'} for ${party}`}
                onChange={(e) => handleSplitChange(e, party, 'value')}
                disabled={editMode !== party} // Only allow editing for the selected party
                className="split-input-field"
              />
              <div className="split-mode-switch-container">
                <Switch
                  checked={initialData?.splits[party]?.mode === 'fixed'}
                  onChange={(checked) => handleSplitChange(checked, party, 'mode')}
                  checkedChildren="Fixed"
                  unCheckedChildren="Percentage"
                  // disabled={editMode !== party} // Only allow mode change for the selected party
                  disabled // Only allow mode change for the selected party
                />
              </div>
              {editMode !== party && (
                <EditOutlined 
                  className="split-edit-icon"
                  onClick={() => setEditMode(party)} // Set the current party to edit mode
                />
              )}
              {editMode === party && (
                <div className="edit-actions">
                  <CheckOutlined 
                    className="action-icon check-icon"
                    onClick={handleSubmit}
                  />
                  <CloseOutlined 
                    className="action-icon close-icon"
                    onClick={() => {
                      setEditMode(null); // Reset the edit mode when cancelling
                    }}
                  />
                </div>
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Splits;
