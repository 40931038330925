export const INIT = 'init'
export const LOADING = 'loading'
export const SUCCESS = 'success'
export const ERROR = 'error'
export const SAVED = 'saved'

export const STATUS_CLASSES ={
  paid: "paid-green",
  approved: "paid-green",
  pending: "red",
  voided: "voided-red",
  succeeded: "paid-green",
}

export function getClassName(taggr){
  let className = 'taggr-account-status'
  if (taggr?.stripeConnectAccount?.isStripeConnected)
    className += ' stripe-connected'
  else if (taggr?.isTrainingPhase)
    className += ' training-completed'
  else if (!taggr?.isTrainingPhase)
    className += ' training-pending'
  return className
}